import React from 'react'
import Layout from '../components/layout'
import Copywriter from '../components/categories/copywriter'
import Seo from '../components/seo'

const CopywriterPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Copywriter />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Copywriter Freelancers | Codestaff'
  const description =
    'Hire the best Copywriter freelancers at Codestaff. Get the top 1% of Copywriter professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default CopywriterPage
