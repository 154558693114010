import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`
const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Does A Copywriter Do?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A copywriter engages and enhances your brand based on your business model, objectives and audience. Copywriting is about being creative but creativity doesn’t come on tap. Copywriting requires talent, experience and ability to deliver an unforgettable experience. If you want to increase your bottom line, you need a copywriter."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Do I Need A Copywriter?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Everyone writes but how we use words differ. You need a copywriter because you want to compel, engage and make your words resonate with your customers naturally. If you want to open up new perspectives on your value proposition, marketing and brand, you should invest in good copy. Codestaff offers the best copywriting services."
        }
    }
}

const Copywriter = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Copywriters.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Copywriters. Top
                                    companies and start-ups choose Codestaff professional Copywriters
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Copywriter now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE COPYWRITERS' banner='/copywriter.png' bannerAlt='copywriter banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default Copywriter